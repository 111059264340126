<template>
  <div>
    <van-form @submit="onSubmit" input-align="right" error-message-align="right" ref="form" :scroll-to-error="true">
      <div class="info">
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">默认信息</span>
          </template>
        </van-cell>
        
        <van-field v-if="showCommunity" name="所属社区" label="所属社区" v-model="show.community" placeholder="请选择所属社区"
          readonly clickable is-link @click="control.community = true"
          :required="dataForm.relationship == 10" :rules="[{ required: true, message: '请选择所属社区' }]" />
        <van-popup v-model="control.community" position="bottom">
          <van-picker show-toolbar value-key="name" :columns="selectList.communityList" @confirm="communityConfirm($event)"
            @cancel="control.community = false" />
        </van-popup>
        
        <van-field name="姓名" label="姓名" v-model="dataForm.userName" placeholder="请输入姓名"
          clearable clear-trigger="always"
          required :rules="[{ required: true, message: '请输入姓名' }]" />

        <van-field name="证件类型" label="证件类型" v-model="show.idNumberTypeStr" placeholder="点击选择证件类型"
          readonly clickable is-link @click="control.idNumberType = true"
          required :rules="[{ required: true, message: '请选择证件类型' }]" />
        <van-popup v-model="control.idNumberType" position="bottom">
          <van-picker show-toolbar value-key="label" :columns="selectList.idNumberTypeList" @confirm="idNumberType"
            @cancel="control.idNumberType = false" />
        </van-popup>

        <van-field name="证件号" label="证件号" v-model="dataForm.idNumber" placeholder="请输入有效证件号"
          clearable clear-trigger="always"
          required :rules="[{ validator: IdentityCodeValid, message: '请输入有效证件号' }]" />

        <van-field name="手机号码" label="手机号码" v-model="dataForm.mobile" placeholder="请输入手机号码"
          clearable clear-trigger="always"
          required :rules="[{ validator: isMobile, message: '请输入正确的手机号码' }]">
        </van-field>

      </div>
      <div class="form-btn-view">
        <van-row class="btns">
          <van-col span="24">
            <van-button type="info" size="large" round native-type="submit">下一步</van-button>
          </van-col>
        </van-row>
      </div>
    </van-form>
  </div>
</template>

<script>
import { IdentityCodeValid, isMobile } from "@/utils/validate"
import { getVirtualDict } from "@/utils/common"
export default {
  data () {
    return {
      showCommunity: false,
      dataForm: {
        community: '',
        userName: '',
        idNumberType: 1,
        idNumber: '',
        mobile: ''
      },
      show: {
        community: '',
        idNumberTypeStr: '身份证'
      },
      control: {
        community: false,
        idNumberType: false
      },
      selectList: {
        communityList: [],
        idNumberTypeList: []
      }
    }
  },
  created () {
    if (this.$globalData.userInfo.street && !this.$orgId) {
      this.showCommunity = true
      this.getCommunityList()
    }
    let that = this
    getVirtualDict("idNumberType", function (virtualDictList) {
      that.selectList.idNumberTypeList = virtualDictList
      that.getInfo()
    })
  },
  methods: {
    IdentityCodeValid(val) {
      return IdentityCodeValid(val)
    },
    isMobile (val) {
      return isMobile(val)
    },
    getCommunityList () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/org/communityList`),
        method: 'get',
        params: this.$http.adornParams({
          street: this.$globalData.userInfo.street
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.selectList.communityList = data.communityList
        }
      })
    },
    communityConfirm (e) {
      this.dataForm.community = e.id
      this.show.community = e.name
      this.control.community = false
    },
    getInfo () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/antiepidemic/basicInfo`),
        method: 'get',
        params: this.$http.adornParams({
          userCode: this.$userCode
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.dataForm.userName = data.name
          this.dataForm.idNumber = data.idNumber
          this.selectList.idNumberTypeList.map(item => {
            if (item.value == data.idNumberType) {
              this.dataForm.idNumberType = data.idNumberType
              this.show.idNumberTypeStr = item.label
            }
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(() => {
        this.$orgId = this.dataForm.community
        this.$router.push({path: '/register', query: {isRes: 1, userName: this.dataForm.userName, idNumber: this.dataForm.idNumber,
          idNumberType: this.dataForm.idNumberType, idNumberTypeStr: this.show.idNumberTypeStr, mobile: this.dataForm.mobile}})
      })
    }
  }
}
</script>